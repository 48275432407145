<template>
  <highcharts class="wrapper-scatter-chart" :options="options" />
</template>

<script>
import { computed } from "@vue/runtime-core";
import { mean } from "simple-statistics";
export default {
  props: {
    industryMaturityScore: {
      type: Array,
      default: [],
    },
    peopleScore: {
      type: [String, Number],
      default: 0,
    },
    techScore: {
      type: [String, Number],
      default: 0,
    },
    industryName: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const data = computed(() => props.industryMaturityScore);
    const techScore = mean(data.value.map((d) => d.avgTechScore));
    const peopleScore = mean(data.value.map((d) => d.avgPropleScore));
    const companyTechPeople = data.value.map((d) => [
      d.avgTechScore,
      d.avgPropleScore,
    ]);
    const options = {
      margin: 0,
      credits: {
        enabled: false,
      },
      title: {
        text: `Your Industry: ${props.industryName} `,
        style: {
          fontWeight: "bolder",
          color: '#3526bf'
        },
      },
      chart: {
        type: "scatter",
        style: {
          fontFamily: "Gotham, sans-serif",
        },
      },
      xAxis: {
        min: 0,
        max: 48,
        labels: {
          style: {
            fontSize: "15px",
          },
        },
        endOnTick: false,
        title: {
          text: "Technology-driven",
          style: {
            fontSize: "15px",
            fontWeight: 'bold'
          },
        },

        plotBands: [
          {
            borderColor: "red",
            from: 45,
            to: 45,
            zIndex: 4,
            label: {
              text: "Total Tech Score",
              verticalAlign: "bottom",
              y: 40,
              width: 20,
            },
            color: "transparent",
          },
        ],
        plotLines: [
          {
            color: "#E0E0E0",
            width: 2,
            value: techScore,
            dashStyle: "dash",
            label: {
              useHTML: true,
              text: `<div class="wrapper-driven-label">
              <div>Industry’s Average </div>
              <div>Tech Score</div>
              </div>`,
              rotation: 0,
              x: -62,
              y: 244,
            },
          },
        ],
      },

      yAxis: {
        margin: 0,
        padding: 0,
        min: 0,
        max: 47,
        endOnTick: false,
        minorTickInterval: 15,
        tickInterval: 15,
        labels: {
          style: {
            fontSize: "15px",
          },
        },
        title: {
          text: "People-driven",
          style: {
            fontSize: "15px",
            fontWeight: 'bold',
          },
        },
        plotBands: [
          {
            borderColor: "red",
            from: 42,
            to: 45,
            zIndex: 4,
            label: {
              text: "Total People Score",
              verticalAlign: "bottom",
              y: -32,
              x: -64,
              width: 20,
            },
            color: "transparent",
          },
        ],
        plotLines: [
          {
            label: {
              useHTML: true,
              text: `<div class="wrapper-driven-label">
              <div>Industry’s Average </div>
              <div>People Score</div>
              </div>`,
              y: -4,
            },
            color: "#E0E0E0",
            width: 2,
            value: peopleScore,
            dashStyle: "dash",
          },
        ],
      },

      series: [
        {
          name: "Your Organization",
          data: [[props.techScore, props.peopleScore]],
          color: "#cc0000",
          marker: {
            width: 30,
            height: 30,
            radius: 8,
          },
          dataLabels: {
            enabled: true,
            distance: 50,
            format: "You are here",
            align: "center",
            allowOverlap: true,
            y: 30,
          },
        },
        {
          name: "Other Organizations in the Same Industry",
          data: companyTechPeople,
          color: "#595959",
        },
      ],
    };
    return {
      options,
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper-scatter-chart {
  &::v-deep {
    .wrapper-driven-label {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
}
</style>
